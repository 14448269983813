<template>
    <PageBlock 
        title="Operations"
        :loading="loading"
        can-export
        can-fullscreen
        is-table
        :list="operations">

        <vxe-table-column 
            field="opvid" 
            title="ID" 
            width="63">
        </vxe-table-column>
        <vxe-table-column 
            field="tdt" 
            title="Trade Date" 
            width="130"
            formatter="formatDateTime">
        </vxe-table-column>
        <vxe-table-column 
            field="t" 
            title="Operation Type" 
            min-width="100">
            <template #default="{ row }">
                <span v-if="row.t">{{row.t.replace(/_/g, " ")}}</span>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            field="tpr" 
            title="Trade Price" 
            min-width="100"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.tpr"
                    :decimals="4"/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            field="q" 
            title="Quantity" 
            min-width="100"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.q"
                    :decimals="2"/>
            </template>
        </vxe-table-column>

    </PageBlock>
</template>

<script>
import PageBlock from '@/components/PageBlock'
import FormattedNumber from '@/components/Formats/FormattedNumber'

export default {
    name: 'isin_trades',
    components: { PageBlock, FormattedNumber },

    props: {
        operations : {
            required: true,
        },
        loading : {
            required: true,
        },
    },
}
</script>
